.debug table {
  border-collapse: collapse;
}

.debug table tr:nth-child(even) {
  background-color: var(--dark-white);
}
.debug table tr:nth-child(odd) {
  background-color: var(--white);
}

.debug table,
.debug th,
.debug td {
  padding: 1em;
  font-size: 0.9em;
}

.debug th {
  font-size: 1em;
  background-color: var(--purple);
  color: var(--white);
}

.container .header{
  background: var(--purple);
  color: var(--white);
  position: relative;
}

.container .header .nprogress-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
}

.container .header .bar {
  display: flex;
  justify-content: space-between;
}

.header .name {
  display: inline-block;
  padding: 12px;
}

.header .icons {
  display: flex;
  align-items: center;
}

.header .icons button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .header .menu-icon {
    display: inline-block;
  }
}

.header .icons .spinner .spinner-icon {
  width: 30px;
  height: 30px;
}

.header .icons input {
  margin: 6px;
  width: 30px;
  height: 30px;
  float: right;
}

:root {
  --disconnected-color: rgba(255, 255, 255, 0.4);
  --connected-color: rgba(255, 255, 255, 0.8);
}

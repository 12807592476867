.container .movie {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}

.poster {
  /* movies: 2:3 ratio */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 300px;
  height: 450px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  -webkit-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

@media (max-width: 425px) {
  .poster {
    width: initial;
  }
}


.poster-tv {
  margin-top: 100px;
  width: 450px;
  height: 300px;
}

.poster_div {
  height: 480px;
  display: grid;
  grid-template-columns: auto 1fr;
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
  top: 50px;
  left: 0px;
  -webkit-transition: left 2.5s ease;
  -moz-transition: left 2.5s ease;
  -ms-transition: left 2.5s ease;
  -o-transition: left 2.5s ease;
  transition: left 2.5s ease;
}

@media (max-width: 425px) {
  .poster_div {
    height: auto;
    grid-template-columns: initial;
  }
}

.poster_div.anim {
  left: -1500px;
}

.background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: inset 0px 0px 500px 300px rgba(0, 0, 0, 0.7);
  height: 300px;
  display: flex;
  flex-wrap: wrap;
}

.qualities {
  flex-direction: row;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

@media (max-width: 1024px) {
  .qualities {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 1680px) {
  .qualities {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.qualityCard {
  background-color: var(--black);
  padding: 20px;
  color: var(--white);
  white-space: nowrap;
  display: grid;
  border-radius: 4px;
}

.qualityInfo {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.qualitySelect {
  background-color: white;
  width: 100%;
  padding: 5px;
  border-radius: 4px;
  margin-top: 10px;
}

.qualityOption {
  font-weight: bold;
}

.quality {
  margin-top: 10px;
}

.downloaded {
  background-color: var(--green);
  color: white;
}

.movie .tools {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.movie .tools button {
  font-size: 1em;
  margin-right: 10px;
}

.movie .tools button:last-child {
  margin-right: 0px;
}

.movie .titles {
  display: grid;
  align-items: normal;
  justify-content: baseline;
  align-self: flex-end;
  height: 200px;
  padding-left: 30px;
  flex-direction: column;
}

@media (max-width: 425px) {
  .movie .titles {
    margin-top: 20px;
    height: auto;
    padding: 0;
  }
}

.movie .titles .title {
  display: flex;
  flex-direction: row;
}

.movie .details {
  position: relative;
  margin-left: 5%;
  margin-right: 5%;
  top: 180px;
  margin-top: 50px;
  -webkit-transition: top 1s ease;
  -moz-transition: top 1s ease;
  -ms-transition: top 1s ease;
  -o-transition: top 1s ease;
  transition: top 1s ease;
}

@media (max-width: 425px) {
  .movie .details {
    top: 400px;
  }
}

.movie .details.anim {
  top: 0px;
}

.movie .details .categoryTitle {
  font-weight: bold;
  font-size: 1.3em;
  margin: 5px 0px;
}

.movie .details .infos {
  margin-bottom: 50px;
}

.movie .infos .castingList {
  text-align: center;
  flex-direction: row;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 15px;
}

.movie .infos .cast_items {
  text-align: right;
  grid-template-rows: 200px 1fr;
}

.movie .infos .castingPoster {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 300px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 425px) {
  .movie .infos .castingPoster {
    height: 600px;
  }
}

.movie .infos .castingName {
  text-align: initial;
  font-weight: bold;
  margin-top: 5px;
}

.movie .infos .castingCharacter {
  text-align: initial;
}

.movie .infos .similarName {
  text-align: initial;
  font-weight: bold;
  margin-top: 5px;
}

.movie .infos .similarCharacter {
  text-align: initial;
}

.movie .infos .similarList {
  text-align: center;
  flex-direction: row;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 15px;
}

.movie .infos .similar_items {
  text-align: center;
  grid-template-rows: 200px 1fr;
}

.movie .infos .similarPoster {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 300px;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 425px) {
  .movie .infos .similarPoster {
    height: 600px;
  }
}

.movie .infos .similarName {
  text-align: initial;
  font-weight: bold;
  margin-top: 5px;
}

.movie .infos .similarCharacter {
  text-align: initial;
}

.movie .titles .name {
  font-size: 2em;
  font-weight: bold;
}

.movie .titles .date {
  font-size: 0.9em;
  font-weight: bold;
  font-style: italic;
  padding: 0px 10px;
  align-self: center;
}

.movie .titles .durate {
  font-size: 1em;
  font-weight: bold;
  color: var(--dark-gray);
  align-self: baseline;
}

.movie .titles .immediatelyPlayableButtonContainer {
  display: grid;
  margin-top: 1.2em;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill,minmax(270px,1fr));
  grid-auto-flow: column;
  grid-auto-columns: minmax(270px,1fr);
  align-self: center;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.immediatelyPlayableButtonContainer::-webkit-scrollbar {
  display: none;
}

.movie .titles .playable {
  align-self: baseline;
}

.movie .infos .overview {
  font-size: 1em;
  font-weight: bold;
  color: var(--gray);
}

.movie-issue-btn {
  align-self: center;
}

.router {
  display: grid;
  grid-template-columns: 200px 1fr;
}

.container {
  background: var(--dark-white);
}

.modal {
  margin: 2em;
  padding: 2em;
  background-color: var(--white);
  border: 1px solid var(--purple);
}

@media (max-width: 768px) {
  .router {
    display: grid;
    grid-template-columns: 1fr;
  }
}

.container .grid .show {
  background: var(--white);
  display: grid;
  grid-template-columns: 200px 1fr;
  height: 300px;
}

.container .grid .show .img {
  background-position: center;
  background-size: cover;
}

.container .grid .show .img .filter {
  opacity: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

.container .grid .show:hover .img .filter {
  opacity: 1;
}

.container .grid .show .img .filter img {
  width: 100px;
}

.container .grid .show .infos {
  padding: 20px;
  overflow-y: scroll;
  height: calc(300px - 40px);
}

.container .grid .show .infos .quality {
  float: right;
  font-size: 0.8em;
  margin-left: 1em;
}

.container .grid .show .infos .watched {
  float: right;
  margin-left: 1em;
}

.container .grid .show .infos .title {
  font-size: 1.1em;
  word-wrap: break-word;
}

.container .grid .show .infos .date {
  color: var(--gray);
}

.container .grid .show .infos .genre {
  text-rendering: none;
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin-top: 8px;
}

.container .grid .show .infos .desc {
  width: 100%;
  text-rendering: none;
  overflow-y: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

@media (max-width: 425px) {
  .container .grid .show {
    grid-template-columns: 1fr;
    height: 130vw;
  }

  .container .grid .show .infos {
    display: none;
  }
}

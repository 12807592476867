.container .grid {
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}

@media (max-width: 1024px) {
  .container .grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 1680px) {
  .container .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.questionElement {
  font-size: 0.8em;
  margin-top: 10px;
}

.questionTitle {
  font-weight: bold;
  font-size: 1em;
}

.questionResp {
  font-style: italic;
}

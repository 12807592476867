.login {
  width: 100vw;
  height: 100vh;
  background: var(--dark-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .center {
  width: 300px;
  text-align: center;
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 10px;
}

.login .center div img {
  padding: 0px;
  margin: 0px;
  width: 200px;
}

.login .error {
  height: 24px;
}

.login .center div input {
  width: calc(100% - 20px);
  border-left: 4px solid var(--purple);
  padding: 10px;
  font-size: 1.1em;
  color: var(--black);
}

.login .center div button {
  width: 100%;
  background: var(--purple);
  border: none;
  border-radius: 4px;
  padding: 10px;
  font-size: 1.1em;
  color: white;
  cursor: pointer;
  outline: none;
}

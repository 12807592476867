.opacity {
  opacity: 0;
}

.hide {
  display: none;
}

.notificationBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.1s;
  z-index: 5;
}

.notificationMessage {
  color: white;
  padding: 15px;
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
}

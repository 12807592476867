.ticketElement {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  grid-gap: 15px;
}

.ticketBox {
  border: 1px solid e4e4e4;
  padding: 10px;
  border-radius: 4px;
  color: white;
}

.ticketTitle {
  font-size: 1.5em;
}

.ticketBody {
  font-size: 1em;
  font-style: italic;
  margin-top: 10px;
}

.ticketClosed {
  background-color: rgba(212, 81, 81, 0.8);
}

.ticketOpen {
  background-color: rgba(103, 212, 80, 0.8);
}

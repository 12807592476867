.support {
  padding: 15px;
}

.ticketText {
  width: 100% !important;
  padding: 5px !important;
  border: 1px solid var(--gray);
  border-radius: 4px;
  resize: none;
  font-family: 'Open Sans', sans-serif;
}

.container .grid .show .infos .props {
  margin-top: 20px;
  height: calc(10% - 20px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.container .grid .show .infos .props div {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.container .grid .show .infos .props div img {
  width: 25px;
}

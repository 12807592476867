.admin {
  padding: 15px;
}

.admin .users table {
  border-collapse: collapse;
  width: 100%;
}

.admin .users table tr:nth-child(even) {
  background-color: var(--dark-white);
}
.admin .users table tr:nth-child(odd) {
  background-color: var(--white);
}

.admin .users table,
.admin .users th,
.admin .users td {
  text-align: center;
  padding: 1em;
  font-size: 0.9em;
}

.admin .users th {
  font-size: 1em;
  background-color: var(--purple);
  color: var(--white);
}

.admin-modal input {
  border-left: 4px solid var(--purple);
  padding: 10px;
  font-size: 1.1em;
}

.admin .admin_notif .desc {
  font-size: 0.8em;
}

.imdbLink:hover {
  cursor: pointer;
}

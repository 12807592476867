.sidebar {
  background: var(--white);
  height: 100vh;
}

.avatar {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.avatar .img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-position: center;
  background-size: cover;
  margin-bottom: 10px;
}

.avatar .bubble {
  width: 18px;
  height: 18px;
  margin-top: -30px;
  margin-right: -80px;
  border-radius: 100%;
  background-color: #e74c3c;
}

.avatar .name {
  margin-top: 30px;
  color: var(--gray);
}

.nav {
  margin-top: 50px;
}

.nav .link {
  display: block;
  text-decoration: none;
  margin-left: 30px;
  font-size: 1.2em;
  margin-bottom: 14px;
  cursor: pointer;
  color: var(--gray);
}

.nav .last {
  margin-top: 30px;
}

.nav .link:active {
  border-right: 4px solid var(--purple);
  color: var(--black);
}

.nav .active {
  border-right: 4px solid var(--purple);
  color: var(--black);
}

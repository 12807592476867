.container .search {
  /**background: var(--white);**/
  position: absolute;
  width: calc(100% - 224px);
  backdrop-filter: blur(200px);
  background-color: color-mix(in srgb, var(--white), transparent 12%);
  padding: 12px;
  border-bottom: 2px solid var(--light-gray);
  z-index: 40;
}

@media (max-width: 425px) {
  .container .search {
    width: calc(100% - 24px);
  }
}

.container .search .input input {
  width: calc(100% - 20px);
  padding: 6px;
  font-size: 1em;
  border-left: 4px solid var(--purple);
  color: var(--black);
}

@import url(//fonts.googleapis.com/earlyaccess/notosanssc.css);
@import url('https://fonts.googleapis.com/css?family=Noto+Sans');

:root {
  --white: #ffffff;
  --dark-white: #f5f5f5;
  --black: #404254;
  --purple: #7e94f7;
  --light-purple: #a1b1f9;
  --dark-purple: #5e7bff;
  --gray: #c8c8cc;
  --dark-gray: #8b8b8b;
  --light-gray: #eff2fa;
  --green: #2ecc71;
  --red: #e74c3c;
}

::-webkit-scrollbar {
  background: none;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: var(--purple);
}

* {
  outline: none;
  scroll-behavior: smooth;
}

html,
body {
  margin: 0px;
  padding: 0px;
}

body {
  font-family: 'Noto Sans', 'Noto Sans SC', sans-serif;
  color: var(--black);
  font-size: 0.84em;
  background: var(--dark-white);
}

input {
  background: none;
  border: none;
  outline: none;
}

button {
  outline: none;
  background: var(--purple);
  border: none;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  padding: 5px;
}

.emoji {
  width: 1em;
  height: 1em;
}

.scrollable {
  height: calc(100vh - 126px);
  overflow-y: scroll;
  padding-top: 68px !important;
}

.scrollable-nopadding {
  height: calc(100vh - 43px);
  padding-top: 54px !important;
  overflow-y: scroll;
}

.settings {
  padding: 15px;
}

.box {
  background: var(--white);
  margin-bottom: 15px;
  padding: 10px;
}

.title {
  margin-bottom: 10px;
}

.element {
  font-size: 0.8em;
}

.element a {
  color: var(--black);
}

.element input {
  border: 1px solid var(--gray);
  padding: 5px;
  border-radius: 4px;
  width: 200px;
  margin-bottom: 5px;
  color: var(--black);
}

.element input[type='radio'] {
  width: auto;
  font-size: 1em;
}

.element input[type='checkbox'] {
  width: auto;
  font-size: 1em;
}

.element button {
  background: var(--purple);
  border: none;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  padding: 5px;
}

.oauth-button {
  font-size: 0.8em;
  padding: 0;
  padding-right: 0.6em;
  margin-bottom: 0.2em;
}

.oauth-button img {
  height: 2em;
  margin: 0.6em;
  vertical-align: middle;
}

.tvtime-button {
  background-color: #373737;
  color: #ffe139;
}

.trakt-button {
  background-color: #1d1d1d;
  color: #ed1c24;
}

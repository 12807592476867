.stats-page {
  width: 100%;
}

.views-from-beginning-chart {
  width: 100%;
}

.views-by-dayname-chart {
  max-width: 300px;
  width: 100%;
}